
import {FC, lazy} from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import {App} from '../App'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
const InterviewForm = lazy(() => import('../pages/interview-form/InterviewForm'))

const AppRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path='/' element={<InterviewForm/>} />
          <Route path='/:code' element={<InterviewForm/>} />
          <Route path='/error/*' element={<ErrorsPage />} /> 
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
